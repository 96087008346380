
<script>
import { watch, ref } from 'vue';
//import  jsPDF  from "jspdf";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import SalesmanShopServices from '@/services/SalesmanShopServices';
import moment from 'moment';
//import htmlToPdfmake from 'html-to-pdfmake';
export default {
  props: {
    day: String,
    salesman_id: String,
    salesman_name: String,
    counter: Number
  },
  setup(props) {
    const root = ref(null);
    const pdfData = ref([]);
    const pdfDate = ref('');

    const documentDefinition = ref({});
    const body = ref([]);
    const totalBalance = ref(0);
    const buildTableBody = async () => {
      const columns = [
        'Sr#',
        'Shop Name',
        'Address',
        'Last Bill Date & Amount',
        'Last Recovery Date & Amount',
        'Current Balance',
        'Received',
        'Remarks',
      ];
      console.log('hey man this is it');
      console.log(columns);
      const body = [];
      await body.push(columns);
      console.log('after body push columns');
      /*await pdfData.value.forEach(function(row) {
                //console.log("this is row");
                //console.log(row['shop_id']);
                const dataRow = [];
                dataRow.push(row['shop_id']);
                dataRow.push(row['shop_name']);
                dataRow.push(row['address']);
                dataRow.push(row['lastBillDate']+"("+row['debitAmount']+")");
                dataRow.push(row['lastRecoveryDate']+"("+row['creditAmount']+")");
                dataRow.push(row['currentBalance']);
                dataRow.push("");
                dataRow.push("");

                
                body.push(dataRow);
            });*/
      return columns;
    };

    const loadPdf = async () => {
      let day_number = 0;
      let current_day = 0;
      current_day = moment().isoWeekday();
      if (props.day === 'monday') {
        day_number = 1;
        //moment().isoWeekday()
      } else if (props.day === 'tuesday') {
        day_number = 2;
      } else if (props.day === 'wednesday') {
        day_number = 3;
      } else if (props.day === 'thursday') {
        day_number = 4;
      } else if (props.day === 'friday') {
        day_number = 5;
      } else if (props.day === 'saturday') {
        day_number = 6;
      } else if (props.day === 'sunday') {
        day_number = 7;
      }

      if (moment().isoWeekday() === day_number) {
        pdfDate.value = moment().format('DD-MM-YYYY');
      } else {
        if (current_day <= day_number) {
          // then just give me this week's instance of that day
          pdfDate.value = moment().isoWeekday(day_number).format('DD-MM-YYYY');
        } else {
          // otherwise, give me *next week's* instance of that same day
          pdfDate.value = moment()
            .add(1, 'weeks')
            .isoWeekday(day_number)
            .format('DD-MM-YYYY');
        }
        //pdfDate.value=moment().startOf('isoWeek').add(day_number, 'day').format('DD-MM-YYYY')
      }
      await SalesmanShopServices.getDailyPdfContent(
        props.salesman_id,
        props.day
      )
        .then((response) => {
          pdfData.value = response.data.data;
        })
        .then(() => {
          let columns = [];
          body.value = [];
          totalBalance.value = 0;
          columns = [
            {
              text: 'Sr#',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 13, 0, 0],
            },
            {
              text: 'Shop Name',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 13, 0, 0],
            },
            {
              text: 'Address',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 13, 0, 0],
            },
            {
              text: 'Last Bill Date & Amount',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 7, 0, 0],
            },
            {
              text: 'Last Recovery Date & Amount',
              style: 'defaultFont',
              alignment: 'center',
            },
            {
              text: 'Current Balance',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            },
            {
              text: 'Received',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 12, 0, 0],
            },
            {
              text: 'Remarks',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 12, 0, 0],
            },
          ];
          body.value.push(columns);

          let i = 1;
          pdfData.value.forEach(function (row) {
            //{text: '1', style: 'defaultFont', alignment: 'center', margin: [0, 6, 0, 0]}
            const dataRow = [];
            const seriol_no = {
              text: i.toString(),
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            };
            dataRow.push(seriol_no);
            const shop_name = {
              style: 'defaultFont',
              alignment: 'center',
            };

            if (row['shop_name'] != '') {
              shop_name['text'] = row['shop_name'].toString();
            } else {
              shop_name['text'] = '';
            }
            dataRow.push(shop_name);
            i++;
            const address = {
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 5, 0, 0],
            };
            if (row['address'] != '' && row['address'] != null) {
              address['text'] = row['address'].toString();
            } else {
              address['text'] = '';
            }
            dataRow.push(address);
            let last_bill_text =
              row['lastBillDate'].toString() + ' (' + row['debitAmount'] + ')';
            if (row['lastBillDate'] == '') {
              last_bill_text = '';
            }
            const last_bill = {
              text: last_bill_text,
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(last_bill);
            let last_recovery_text =
              row['lastRecoveryDate'].toString() +
              ' (' +
              row['creditAmount'] +
              ')';
            if (row['lastRecoveryDate'] == '') {
              last_recovery_text = '';
            }
            const last_recovery = {
              text: last_recovery_text,
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(last_recovery);
            const current_balance = {
              text: row['currentBalance'].toString(),
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            };
            dataRow.push(current_balance);
            dataRow.push('');
            dataRow.push('');
            if (row['currentBalance'] != '') {
              totalBalance.value =
                eval(totalBalance.value) + eval(row['currentBalance']);
            }
            body.value.push(dataRow);
          });
        })
        .then(() => {
          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text:
                      'Salesman:' +
                      props.salesman_name +
                      ' (' +
                      props.salesman_id +
                      ')',
                    style: ['defaultFont', 'mb2'],
                  },

                  {
                    width: '51%',
                    text: 'Day:' + props.day + ' ' + 'Date:' + pdfDate.value,
                    style: ['defaultFont', 'textRight', 'mb2'],
                  },
                ],

                columnGap: 8,
              },
              {
                table: {
                  headerRows: 1,
                  //widths: ['auto', 70, 'auto', 68, 70, 60, 'auto', 'auto'],
                  body: body.value,
                },
              },

              {
                columns: [
                  {
                    width: '28%',
                    text: 'Total:',
                    style: ['defaultFont', 'textCenter', 'mt2'],
                  },

                  {
                    width: '92%',
                    text: totalBalance.value.toFixed(2),
                    style: ['defaultFont', 'textCenter', 'mt2'],
                  },
                ],

                columnGap: 2,
              },
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mt2: {
                margin: [0, 2, 0, 0],
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
        });
    };
    watch(
      () => props.counter,
      (currentValue, oldValue) => {
        loadPdf();
      }
    );

    return {
      root,
      pdfData,
      pdfDate,
      buildTableBody,
      documentDefinition,
      body,
      totalBalance,
    };
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
.pdf-wrapper {
  margin: 40px 30px;
}
.text-black {
  color: #000;
}
.w-100 {
  width: 100%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.font-weight-bold {
  font-weight: 600;
}
.font-13 {
  font-size: 13px;
}
.text-upper {
  text-transform: uppercase;
}
.text-capitalized {
  text-transform: capitalize;
}
.dis-inline-block {
  display: inline-block;
}
.v-align-middle {
  vertical-align: middle;
}
.clearfixed:after {
  content: '';
  display: table;
  clear: both;
}
table {
  border-collapse: collapse;
  line-height: 14px;
}
.border-table,
.border-table th,
.border-table td {
  border: 2px solid #000;
}
.border-table th,
.border-table td {
  padding: 4px 6px;
  min-width: 110px;
  max-width: 120px;
}
.border-table th.small-size,
.border-table td.small-size {
  min-width: 50px;
  max-width: 50px;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.p-0 {
  padding: 0;
}
.m-0 {
  margin: 0;
}
</style>
