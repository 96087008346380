<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import BillingServices from '@/services/BillingServices';
import moment from 'moment';
export default {
  props: {
    billing_id: Number,
    salesman_id: Number,
    salesman_name: String,
    billing_date: String,
    counter: Number
  },
  setup(props) {
    const root = ref(null);
    const pdfData = ref([]);
    const documentDefinition = ref({});
    const body = ref([]);
    const totalBalance = ref(0);
    const totalCurrentBill = ref(0);
    const dayName = ref('');
    const buildTableBody = async () => {
      const columns = [
        'Sr#',
        'Shop Name',
        'Address',
        'Last Bill Date & Amount',
        'Last Recovery Date & Amount',
        'Current Balance',
        'Received',
        'Remarks',
      ];
      console.log('hey man this is it');
      console.log(columns);
      const body = [];
      await body.push(columns);
      console.log('after body push columns');

      return columns;
    };

    const loadPdf = async () => {
      await BillingServices.getMainPdfContent(
        props.salesman_id,
        props.billing_id
      )
        .then((response) => {
          pdfData.value = response.data.data;
        })
        .then(() => {
          let columns = [];
          body.value = [];
          totalBalance.value = 0;
          totalCurrentBill.value = 0;
          columns = [
            {
              text: 'Sr#',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            },
            {
              text: 'Shop Name',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            },
            {
              text: 'Address',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            },
            {
              text: 'Previous Balance',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 0, 0, 0],
            },
            { text: 'Current Bill', style: 'defaultFont', alignment: 'center' },
            {
              text: 'Total',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            },
            {
              text: 'Cash Received',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 0, 0, 0],
            },
            {
              text: 'Shopkeeper Sign',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 0, 0, 0],
            },
          ];
          body.value.push(columns);

          let i = 1;
          pdfData.value.forEach(function (row) {

            const dataRow = [];
            const seriol_no = {
              text: i.toString(),
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            };
            dataRow.push(seriol_no);
            const shop_name = {
              style: 'defaultFont',
              alignment: 'center',
            };

            if (row['shop_name'] != '') {
              //shop_name['text']="malik asgher medical store";
              shop_name['text'] = row['shop_name'].toString();
            } else {
              shop_name['text'] = '';
            }
            dataRow.push(shop_name);

            const address = {
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 5, 0, 0],
            };

            console.log(i);
            if (row['address'] != '') {
              //address['text']="adil";
              address['text'] = row['address'].toString();
            } else {
              address['text'] = '';
            }

            dataRow.push(address);

            const prev_balance = {
              text: row['previousBalance'].toString(),
              //text: "123"
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 5, 0, 0],
            };

            dataRow.push(prev_balance);

            const current_bill = {
              text: row['currentBill'].toString(),
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 5, 0, 0],
            };
            dataRow.push(current_bill);

            const total = {
              text: row['total'].toString(),
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            };
            dataRow.push(total);

            const empty1 = {
              text: '-',
              style: 'defaultFont',
              alignment: 'center',
              margin: [0, 6, 0, 0],
            };
            dataRow.push(empty1);
            dataRow.push(empty1);

            if (row['currentBill'] != '') {
              totalCurrentBill.value =
                eval(totalCurrentBill.value) + eval(row['currentBill']);
            }
            if (row['total'] != '') {
              totalBalance.value =
                eval(totalBalance.value) + eval(row['total']);
            }
            body.value.push(dataRow);

            //}
            i++;
          });
        })
        .then(() => {
          const days = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ];
          const d = new Date(props.billing_date);
          dayName.value = days[d.getDay()];

          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text:
                      'DATE:' + moment(props.billing_date).format('DD-MM-YYYY'),
                    style: ['defaultFont', 'mb10'],
                  },

                  {
                    width: '51%',
                    text: 'Day:' + dayName.value,
                    style: ['defaultFont', 'textRight', 'mb10'],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: '100%',
                    text:
                      'SALESMAN: ' +
                      props.salesman_name +
                      ' (' +
                      props.salesman_id +
                      ')',
                    style: ['defaultFont', 'mb2'],
                  },
                ],
              },
              {
                table: {
                  headerRows: 1,
                  //widths: ['auto', 'auto', 'auto', 60, 60, 60, 'auto', 'auto'],

                  body: body.value,
                },
              },
              {
                columns: [
                  {
                    width: '15%',
                    text: 'Total:',
                    style: ['defaultFont', 'textRight', 'mt2'],
                  },
                  {
                    width: '39%',
                    text: totalCurrentBill.value.toFixed(2),
                    style: ['defaultFont', 'textRight', 'mt2'],
                  },
                  {
                    width: '33%',
                    text: totalBalance.value.toFixed(2),
                    style: ['defaultFont', 'mCustom'],
                  },
                ],
              },
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mt2: {
                margin: [0, 2, 0, 0],
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
              mb10: {
                margin: [0, 0, 0, 10],
              },
              mCustom: {
                margin: [33, 2, 0, 0],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
        });
    };
    watch(
      () => props.counter,
      (currentValue, oldValue) => {
        if (currentValue != oldValue && currentValue != '') {
          loadPdf();
        }
      }
    );

    return {
      root,
      pdfData,
      buildTableBody,
      documentDefinition,
      body,
      totalBalance,
      totalCurrentBill,
      dayName,
    };
  },
};
</script>
