<template>
    <!-- Edit Modal -->
	<div class="modal fade p-0" id="editSalesman" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg">
			<div class="d-flex flex-wrap w-100 position-relative event-auto">
				<div class="card shadow-none">
          <Form class="custom-form mt-2 row mx-0" @submit="doSubmitEdit" @keydown="errors_v.clear($event.target.name)" :validation-schema="schema" v-slot="{ errors }">
					
          <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
						<div class="content-card-left w-100">
							<h4 class="card-title text-white fs-4">Salesman</h4>
							<p class="fw-light text-white mb-0 pt-1">Salesman Information</p>
						</div>
					</div>
					<div class="card-body" >
							<div class="col-lg-6 col-12 mb-4">
								<label for="" class="d-block mb-2 placeholder-label position-static transform-none">Upload Cnic Image(front)</label>
								<div class="position-relative form-group ">
									<Field type="file" name="cnic_front" id="upload_front_edit" hidden @change="fileUploadEdit($event,'front')" accept="image/*" />
									<label class="upload-label d-inline-block position-relative d-flex flex-wrap align-items-center justify-content-center" for="upload_front_edit">
										<h2 class="text-center mb-0" v-if="imgUpload == ''">Upload Cnic Image(Front) </h2>
										<figure class="uploaded-image" v-if="imageUrlFront">
											<img v-show="imageUrlFront" :src="imageUrlFront" alt="UploadImage" class="w-100 h-100 shadow">
										</figure>
										<div class="overlay position-absolute d-flex flex-wrap align-items-center justify-content-center" v-if="imgUpload == ''">
											<h4 class="text-center mb-0 text-white">
												<i class="bi bi-cloud-arrow-up text-white d-block"></i>
												Upload Image
											</h4>
										</div>
									</label>
									<!-- Cancel button -->
									<a href="#" class="btn btn-cancel rounded-circle p-0 position-absolute" @click.prevent="imgUpload = ''" v-if="imgUpload">
										<i class="bi bi-x"></i>
									</a>
                  <p v-if="errors.cnic_front" class="invalid-message mb-0 pt-2">
                      {{errors.cnic_front}}
                  </p>
                  <p v-if="errors_v.get('cnic_front')" class="invalid-message mb-0 pt-2">
                        {{errors_v.get('cnic_front')}}
                  </p>
								</div>
							</div>
              
							<div class="col-lg-6 col-12 mb-4">
								<label for="" class="d-block mb-2 placeholder-label position-static transform-none">Upload Cnic Image(front)</label>
								<div class="position-relative form-group ">
									<Field type="file" name="cnic_back" id="upload_back_edit" hidden @change="fileUploadEdit($event, 'back')" accept="image/*" />
									<label class="upload-label d-inline-block position-relative d-flex flex-wrap align-items-center justify-content-center" for="upload_back_edit">
										<h2 class="text-center mb-0" v-if="imgUpload == ''">Upload Cnic Image(Front) </h2>
										<figure class="uploaded-image" v-if="imageUrlBack">
											<img v-show="imageUrlBack" :src="imageUrlBack" alt="UploadImage" class="w-100 h-100 shadow">
										</figure>
										<div class="overlay position-absolute d-flex flex-wrap align-items-center justify-content-center" v-if="imgUpload == ''">
											<h4 class="text-center mb-0 text-white">
												<i class="bi bi-cloud-arrow-up text-white d-block"></i>
												Upload Image
											</h4>
										</div>
									</label>
									<!-- Cancel button -->
									<a href="#" class="btn btn-cancel rounded-circle p-0 position-absolute" @click.prevent="imgUpload = ''" v-if="imgUpload">
										<i class="bi bi-x"></i>
									</a>
                  <p v-if="errors.cnic_back" class="invalid-message mb-0 pt-2">
                      {{errors.cnic_back}}
                  </p>
                  <p v-if="errors_v.get('cnic_back')" class="invalid-message mb-0 pt-2">
                        {{errors_v.get('cnic_back')}}
                  </p>
								</div>
							</div>
							<div class="col-lg-6 col-12 mb-4">
								<div class="position-relative form-group ">
									<Field type="text" name="first_name" :class="[form.first_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.first_name"/>
									<label for="" class="mb-0 placeholder-label">First Name</label>
                  <p v-if="errors.first_name" class="invalid-message mb-0 pt-2">
                      {{errors.first_name}}
                  </p>
                  <p v-if="errors_v.get('first_name')" class="invalid-message mb-0 pt-2">
                        {{errors_v.get('first_name')}}
                  </p>
                </div>
							</div>
							<div class="col-lg-6 mb-4">
								<div class="position-relative form-group ">
									<Field type="text" name="last_name" :class="[form.last_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.last_name"/>
									<label for="" class="mb-0 placeholder-label">Last Name</label>
                  <p v-if="errors.last_name" class="invalid-message mb-0 pt-2">
                      {{errors.last_name}}
                  </p>
                  <p v-if="errors_v.get('last_name')" class="invalid-message mb-0 pt-2">
                        {{errors_v.get('last_name')}}
                  </p>
                </div>
							</div>
							<div class="col-lg-6 mb-4">
								<div class="position-relative form-group ">
									<Field type="text" name="cnic" :class="[form.cnic=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.cnic"/>
									<label for="" class="mb-0 placeholder-label">Cnic</label>
                  <p v-if="errors.cnic" class="invalid-message mb-0 pt-2">
                      {{errors.cnic}}
                  </p>
                  <p v-if="errors_v.get('cnic')" class="invalid-message mb-0 pt-2">
                        {{errors_v.get('cnic')}}
                  </p>
                </div>
							</div>
							<div class="col-lg-6 mb-4">
								<div class="position-relative form-group">
									<Field type="number" name="mobile" :class="[form.mobile=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.mobile"/>
									<label for="" class="mb-0 placeholder-label">Mobile Number</label>
                  <p v-if="errors.mobile" class="invalid-message mb-0 pt-2">
                      {{errors.mobile}}
                  </p>
                  <p v-if="errors_v.get('mobile')" class="invalid-message mb-0 pt-2">
                        {{errors_v.get('mobile')}}
                  </p>
                </div>
							</div>
							<div class="col-lg-12 mb-4">
								<div class="position-relative form-group ">
									<Field name="address" :class="[form.address=='' ? '' : 'active', 'border-0 position-relative w-100 resize-none']" v-model="form.address" rows="5" as="textarea"></Field>
									<label for="" class="mb-0 placeholder-label">Address</label>
                  <p v-if="errors.address" class="invalid-message mb-0 pt-2">
                      {{errors.address}}
                  </p>
                  <p v-if="errors_v.get('address')" class="invalid-message mb-0 pt-2">
                        {{errors_v.get('address')}}
                  </p>
                </div>
							</div>
						
					</div>
					<div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
						<button class="btn btn-primary w-100" :disabled="searchDisabled">
                  <div v-if="loader" class="spinner-border me-2" role="status"></div>Edit Salesman
                </button>
					</div>
          </Form>
				</div>
			</div>
		</div>			
	</div>
  
</template>

<script>
    import {watch,ref, reactive, onBeforeUpdate } from 'vue';
    import Errors from "@/utils/Errors";
    import { Form, Field  } from 'vee-validate';
    import * as Yup from 'yup'; 
    import SalesmanServices from '@/services/SalesmanServices';
    
export default{
    components:{
            Form,
            Field
        },
    props: {
        salesman_id: Number
    },
    setup(props,{emit}){
        const loader=ref(false);
        const searchDisabled=ref(false);
        const watchVar=ref("");
        
        const initialState = {
          first_name: "",
          last_name: "",
          cnic: "",
          mobile: "",
          address: "",
          cnic_front: "",
          cnic_back: "",
          cnic_front_change:false,
          cnic_back_change: false
        };
        const imageFile=ref("");
        const imageUrlFront=ref("");
        const imageUrlBack=ref("");
        const form = reactive({ ...initialState });
        const SUPPORTED_FORMATS=['image/jpg', 'image/jpeg', 'image/png'];
        const checkIfFilesAreCorrectTypes= (file_name,type)=> {
          let valid = true
         
          
          if(type==="front" && form.cnic_front_change===false){
            return valid;
          }
          if(type==="back" && form.cnic_back_change===false){
            return valid;
          }
          if (file_name) {
            if (!SUPPORTED_FORMATS.includes(file_name.type)) {
                valid = false
            }
          }
          return valid
        }

        const checkIfFileSizeAreCorrects= (file_name,type)=> {
          
          
          let valid = true;
          if(type==="front" && form.cnic_front_change===false){
            return valid;
          }
          if(type==="back" && form.cnic_back_change===false){
            return valid;
          }
          if(file_name){
            if(file_name.size/1024 > 2048){
              valid = false;
            }
          }
          return valid;
        }
        const schema = Yup.object().shape({
            first_name: Yup.string()
                .required('First Name is required'),
            last_name: Yup.string()
                .required('Last Name is required'),
            cnic: Yup.string()
                .required('CNIC is required'),
            mobile: Yup.number()
                .typeError("Only Numbers Allowed"),
            address: Yup.string()
                .required("Address required"),
            cnic_front: Yup.mixed()
                .test(
                  'fileSize',
                  'File too large',
                  () => checkIfFileSizeAreCorrects(form.cnic_front,"front")
                )
                
                .test(
                  'fileFormat',
                  'Unsupported file type',
                  () => checkIfFilesAreCorrectTypes(form.cnic_front,"front")
                ),
                
            cnic_back: Yup.mixed()
                .test(
                  'fileSize',
                  'File too large',
                  () =>   checkIfFileSizeAreCorrects(form.cnic_back,"back")
                  )
                  .test(
                  'fileFormat',
                  'Unsupported file type',
                  () => checkIfFilesAreCorrectTypes(form.cnic_back,"back")
                )
                
        });
        
        const errors_v = reactive(new Errors());
        
        const fileUploadEdit=(event,attr)=>{
          
          if(event.target.files.length===0){
            imageFile.value="";
            if(attr==="front"){
              imageUrlFront.value="";
              watchVar.value="";
            }
            else{
              imageUrlBack.value="";
              watchVar.value="";
            }
            return;
          }

          if(attr==="front"){
            form.cnic_front=event.target.files[0];
            watchVar.value="front";
            form.cnic_front_change=true;
          }
          if(attr==="back"){
            form.cnic_back=event.target.files[0];
            watchVar.value="back";
            form.cnic_back_change=true;
          }
          
        }
        
        watch(watchVar, (currentValue, oldValue) => {
          
          if(currentValue===""){
            return;
          }
          
          watchVar.value="";
          const fileReader=new FileReader();
          if(currentValue==="front"){
            
            fileReader.readAsDataURL(form.cnic_front);
            fileReader.addEventListener("load",()=>{
            imageUrlFront.value=fileReader.result;
            watchVar.value="";
            })
          }
          else if(currentValue==="back"){
            fileReader.readAsDataURL(form.cnic_back);
            fileReader.addEventListener("load",()=>{
            imageUrlBack.value=fileReader.result;
            watchVar.value="";
            })
          }
          
        });
        
        const closeModal= ()=>{
            const myModalEl = document.getElementById('editSalesman');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
        }

        onBeforeUpdate(async() => {
          
          if(props.salesman_id>0){
            await SalesmanServices.getOneSalesman(props.salesman_id)
            .then(response=>{
                imageUrlFront.value=response.data.cnic_front_uploaded
                imageUrlBack.value=response.data.cnic_back_uploaded
                form.first_name=response.data.first_name
                form.last_name=response.data.last_name
                form.mobile=response.data.mobile
                form.cnic=response.data.cnic
                form.address=response.data.address

          })
          .catch(error => {
                
                if(error.response.status==404){
                  closeModal();
                  emit('alertEvent', 'no_data');
                }
            });

          }
          else{
            closeModal();
            emit('alertEvent', 'no_data');
          }
        })

        const doSubmitEdit = async(values, actions) => {
          
          loader.value=true;
          searchDisabled.value=true;
          const data= new FormData();
          data.append('first_name', form.first_name);
          data.append('last_name', form.last_name);
          data.append('mobile', form.mobile);
          data.append('cnic', form.cnic);
          data.append('address', form.address);
          data.append('cnic_front', form.cnic_front);
          data.append('cnic_back', form.cnic_back);
          data.append('cnic_front_change', form.cnic_front_change);
          data.append('cnic_back_change', form.cnic_back_change);
          data.append('_method', 'put');
          console.log(data);
          await SalesmanServices.updateSalesman(data,props.salesman_id)
              .then(async response=>{
                closeModal();
                loader.value=false;
                searchDisabled.value=false;
                emit('alertEvent', 'edit');
              })
              .catch(error => {
                alert("error");
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
                  errors_v.record(error.response.data.errors);
                }
              });
        }
        return {
            schema,
            form,
            doSubmitEdit,
            errors_v,
            loader,
            searchDisabled,
            fileUploadEdit,
            imageFile,
            imageUrlFront,
            imageUrlBack,
            watchVar,
            closeModal
        }
    }
}
</script>