<template>
    <!-- Edit Modal -->
	<div class="modal fade p-0" id="addProduct" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg">
			<div class="d-flex flex-wrap w-100 position-relative event-auto">
				<div class="card shadow-none">
          <Form class="custom-form mt-2" @submit="doSubmit" @keydown="errors_v.clear($event.target.name)" :validation-schema="schema" v-slot="{ errors }">
					<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
						<div class="content-card-left w-100">
							<h4 class="card-title text-white fs-4">Product</h4>
							<p class="fw-light text-white mb-0 pt-1">Add a New Product</p>
						</div>
					</div>
					<div class="card-body px-lg-4 px-md-4 px-sm-4" >
						
							<div class="position-relative mb-5">
								<Field type="text" name="product_name" :class="[form.product_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.product_name"/>
								<label for="" class="mb-0 placeholder-label">Name</label>
                <p v-if="errors.product_name" class="invalid-message mb-0 pt-2">
                      {{errors.product_name}}
                </p>
                <p v-if="errors_v.get('name')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('name')}}
                  </p>
              </div>
              <div class="position-relative mb-5">
								<Field type="text" name="product_urdu_name" :class="[form.product_urdu_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.product_urdu_name"/>
								<label for="" class="mb-0 placeholder-label">Urdu Name</label>
                <p v-if="errors.product_urdu_name" class="invalid-message mb-0 pt-2">
                      {{errors.product_urdu_name}}
                </p>
                <p v-if="errors_v.get('urdu_name')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('urdu_name')}}
                  </p>
              </div>
              <div class="position-relative mb-5">
								<Field type="text" name="product_extra_info" :class="[form.product_extra_info=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.product_extra_info"/>
								<label for="" class="mb-0 placeholder-label">Extra Info</label>
                
              </div>
              <div class="position-relative mb-5">
								<Field type="number" name="boxes_per_carton" :class="[form.boxes_per_carton=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.boxes_per_carton"/>
								<label for="" class="mb-0 placeholder-label">Box Per Carton</label>
                <p v-if="errors.boxes_per_carton" class="invalid-message mb-0 pt-2">
                      {{errors.boxes_per_carton}}
                </p>
                <p v-if="errors_v.get('boxes_per_carton')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('boxes_per_carton')}}
                  </p>
              </div>
              <div class="position-relative mb-5">
								<Field type="number" name="initial_price_per_unit" :class="[form.initial_price_per_unit=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.initial_price_per_unit"/>
								<label for="" class="mb-0 placeholder-label">Initial Price Per Unit</label>
                <p v-if="errors.initial_price_per_unit" class="invalid-message mb-0 pt-2">
                      {{errors.initial_price_per_unit}}
                </p>
                <p v-if="errors_v.get('initial_price_per_unit')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('initial_price_per_unit')}}
                </p>
              </div>
							<div class="position-relative form-group mb-4">
								<Field class="form-select custom-select" name="discount_type" @click="selectFocus = true" as="select" v-model="form.discount_type" @change="onChange($event)" >
									<option value="rupees" selected>Ruppes</option>
									<option value="percent">Percent</option>
									<option value="no-discount">No Discount</option>
								</Field>
                <p v-if="errors.discount_type" class="invalid-message mb-0 pt-2">
                      {{errors.discount_type}}
                </p>
                <p v-if="errors_v.get('discount_type')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('discount_type')}}
                </p>
								<label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Discount Type</label>
								<i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
							</div>
							<div :class="[showDiscount ? 'd-block':'d-none', 'position-relative mb-5']">
								<Field type="number" name="discount" :class="[form.discount=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.discount"/>
								<label for="" class="mb-0 placeholder-label">Discount</label>
                <p v-if="errors.discount" class="invalid-message mb-0 pt-2">
                      {{errors.discount}}
                </p>
                <p v-if="errors_v.get('discount')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('discount')}}
                </p>

              </div>
                            <div class="position-relative mb-5">
								<Field type="number" name="expense_per_unit" :class="[form.expense_per_unit=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.expense_per_unit"/>
								<label for="" class="mb-0 placeholder-label">Expense</label>
                <p v-if="errors.expense_per_unit" class="invalid-message mb-0 pt-2">
                      {{errors.expense_per_unit}}
                </p>
                <p v-if="errors_v.get('expense_per_unit')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('expense_per_unit')}}
                </p>
              </div>
              <div class="position-relative mb-5">
								<Field type="number" name="whole_sale_price" :class="[form.whole_sale_price=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.whole_sale_price"/>
								<label for="" class="mb-0 placeholder-label">whole Sale</label>
                <p v-if="errors.whole_sale_price" class="invalid-message mb-0 pt-2">
                      {{errors.whole_sale_price}}
                </p>
                <p v-if="errors_v.get('whole_sale_price')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('whole_sale_price')}}
                </p>
              </div>
              <div class="position-relative mb-5">
								<Field type="number" name="retail_price" :class="[form.retail_price=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.retail_price"/>
								<label for="" class="mb-0 placeholder-label">Retail Price</label>
                <p v-if="errors.retail_price" class="invalid-message mb-0 pt-2">
                      {{errors.retail_price}}
                </p>
                <p v-if="errors_v.get('retail_price')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('retail_price')}}
                </p>
              </div>
						
					</div>
					<div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
						<button class="btn btn-primary w-100" :disabled="searchDisabled">
                  <div v-if="loader" class="spinner-border me-2" role="status"></div>Add Product
                </button>
					</div>
          </Form>
				</div>
			</div>
		</div>			
	</div>
  
</template>

<script>
    import {ref, reactive } from 'vue';
    import Errors from "@/utils/Errors";
    import { Form, Field  } from 'vee-validate';
    import * as Yup from 'yup'; 
    import ProductServices from '@/services/ProductServices';
    
export default{
    components:{
        Form,
        Field
        },
    props: {
    		company_id: Number
  		},
    setup(props,{emit}){
        const loader=ref(false);
        const searchDisabled=ref(false);
        const showDiscount = ref(false);
        const initialState = {
          product_name: "",
          product_urdu_name: "",
          product_extra_info: "",
          boxes_per_carton: "",
          initial_price_per_unit: "",
          discount_type: "no-discount",
          discount: "",
          expense_per_unit: 0,
          whole_sale_price: "",
          retail_price: ""
        };
        
        const form = reactive({ ...initialState });
        const onChange= (event)=>{
          
            if(event.target.value=="rupees" || event.target.value=="percent"){
              
              showDiscount.value=true;
            }
            else{
              showDiscount.value=false;
              form.discount=0;
            }
        }
        const schema = Yup.object().shape({
            product_name: Yup.string()
                .required('Product Name is required'),
            product_urdu_name: Yup.string()
                .required('Product Urdu Name is required'),
            boxes_per_carton: Yup.number()
                .typeError("Only Numbers Allowed"),
            initial_price_per_unit: Yup.number()
                .typeError("Only Numbers Allowed"),
            discount_type: Yup.string()
                .required('Discount Type is required'),
            discount:
                Yup.number()
                .when("discount_type", (discount_type) => {
                if(discount_type=="rupees" || discount_type=="percent")
                  return Yup.number().typeError("Only Numbers Allowed")
           }),
            expense_per_unit: Yup.number()
                .typeError("Only Numbers Allowed"),
            whole_sale_price: Yup.number()
                .typeError("Only Numbers Allowed"),
            retail_price: Yup.number()
                .typeError("Only Numbers Allowed"),
        });
        const closeModal= ()=>{
            const myModalEl = document.getElementById('addProduct');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
          }
        const errors_v = reactive(new Errors());
        function resetForm() {
            Object.assign(form, initialState);
          }
        const doSubmit = async(values, actions) => {
          
          loader.value=true;
          searchDisabled.value=true;
          const payload = {
            product_name: form.product_name,
            product_urdu_name: form.product_urdu_name,
            product_extra_info: form.product_extra_info,
            boxes_per_carton: form.boxes_per_carton,
            initial_price_per_unit: form.initial_price_per_unit,
            discount_type: form.discount_type,
            discount: form.discount,
            expense_per_unit: form.expense_per_unit,
            whole_sale_price: form.whole_sale_price,
            retail_price: form.retail_price,
            company_id: props.company_id
          };
         
          await ProductServices.insertProduct(payload)
              .then(async response=>{
                
                closeModal();
                emit('alertEvent', 'add');
                loader.value=false;
                searchDisabled.value=false;
                
                actions.resetForm();
              })
              .catch(error => {
                alert("asdfasdf");
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
                  errors_v.record(error.response.data.errors);
                }
              });
        }
        return {
            schema,
            form,
            doSubmit,
            errors_v,
            resetForm,
            loader,
            searchDisabled,
            closeModal,
            showDiscount,
            onChange          

        }
    }
}
</script>