<template>
    <!-- View Modal -->
<div class="modal fade p-0" id="viewSalesman" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg">
		<div class="d-flex flex-wrap w-100 position-relative event-auto">
			<div class="card shadow-none">
				<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
					<div class="content-card-left w-100">
						<h4 class="card-title text-white fs-4">Salesman</h4>
						<p class="fw-light text-white mb-0 pt-1">Salesman Information</p>
					</div>
				</div>
				<div class="card-body" >
					<form class="custom-form mt-2 row mx-0" @submit.prevent.stop>
						<div class="col-lg-6 col-12 mb-4">
							<div class="position-relative form-group ">
								<label for="" class="d-block mb-2 placeholder-label position-static transform-none">Cnic Front Image</label>
								<figure class="uploaded-image">
									<img :src="form.cnic_front" alt="front_Cnic" class="w-100 h-100 shadow">
								</figure>
							</div>
						</div>
						<div class="col-lg-6 col-12 mb-4">
							<div class="position-relative form-group ">
								<label for="" class="d-block mb-2 placeholder-label position-static transform-none">Cnic Back Image</label>
								<figure class="uploaded-image">
									<img :src="form.cnic_back" alt="front_Cnic" class="w-100 h-100 shadow">
								</figure>
							</div>
						</div>
						<div class="col-lg-6 col-12 mb-4">
							<div class="position-relative form-group ">
								<label for="" class="mb-0 placeholder-label position-static transform-none">First Name</label>
								<input disabled class="border-0 position-relative w-100 px-3 mt-2" v-model="form.first_name"  />
							</div>
						</div>
						<div class="col-lg-6 col-12 mb-4">
							<div class="position-relative form-group ">
								<label for="" class="mb-0 placeholder-label position-static transform-none">Last Name</label>
								<input disabled class="border-0 position-relative w-100 px-3 mt-2" v-model="form.last_name"/>
							</div>
						</div>
						<div class="col-lg-6 col-12 mb-4">
							<div class="position-relative form-group ">
								<label for="" class="mb-0 placeholder-label position-static transform-none">Cnic</label>
								<input disabled class="border-0 position-relative w-100 px-3 mt-2" v-model="form.cnic"/>
							</div>
						</div>
						<div class="col-lg-6 col-12 mb-4">
							<div class="position-relative form-group ">
								<label for="" class="mb-0 placeholder-label position-static transform-none">Mobile Number</label>
								<input disabled class="border-0 position-relative w-100 px-3 mt-2" v-model="form.mobile"/>
							</div>
						</div>
						<div class="col-12">
							<div class="position-relative form-group ">
								<label for="" class="mb-0 placeholder-label position-static transform-none">Address</label>
								<textarea disabled class="border-0 position-relative w-100 px-3 mt-2 pt-2" rows="3" v-model="form.address" ></textarea>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>			
</div>
</template>

<script>
import SalesmanServices from '@/services/SalesmanServices';
import {onBeforeUpdate, reactive} from 'vue';
export default {
    props: {
        salesman_id: Number
    },
    setup(props,{emit}){
        
        const initialState = {
          cnic_front: "",
          cnic_back: "",
          first_name: "",
          last_name: "",
          mobile: "",
          cnic: "",
          address: "",

        };
        
        const form = reactive({ ...initialState });

        const closeModal= ()=>{
            const myModalEl = document.getElementById('editCompany');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
        }
        onBeforeUpdate(async() => {
          
          if(props.salesman_id>0){
            await SalesmanServices.getOneSalesman(props.salesman_id)
            .then(response=>{
                form.cnic_front=response.data.cnic_front_uploaded
                form.cnic_back=response.data.cnic_back_uploaded
                form.first_name=response.data.first_name
                form.last_name=response.data.last_name
                form.mobile=response.data.mobile
                form.cnic=response.data.cnic
                form.address=response.data.address
              
          })
          .catch(error => {
                
                if(error.response.status==404){
                  closeModal();
                  emit('alertEvent', 'no_data');
                }
            });

          }
          else{
            closeModal();
            emit('alertEvent', 'no_data');
          }
        })
        return {
            closeModal,
            form
        }
    }
}
</script>

