<template>
  <!-- Inner content -->
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Shop Daily</h4>
                </div>
                <div class="content-card-right ms-auto">
                  <a
                    href="#addGenerate"
                    data-bs-toggle="modal"
                    data-bs-target="#addGenerate"
                    @click.prevent
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <form
                    class="table-header mx-0 mb-1 custom-form position-sticky"
                  >
                    <div class="row m-0 align-items-center">
                      <div
                        class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 px-0"
                      >
                        <h4 class="filter mb-lg-0 mb-md-0 mb-3">
                          <i class="bi bi-funnel me-1 position-relative"></i
                          >Filters
                        </h4>
                      </div>
                      <div
                        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto"
                      >
                        <div class="position-relative w-100">
                          <Multiselect v-model="searchData.shopId"
                          :options="shops" class="custom-multiselect
                          table-header-multi h-auto" placeholder="Search Shop"
                          :searchable="true" :showOptions="true" mode="single"
                          label="shopName" trackBy = "shop_name" valueProp="id"
                          required: true />

                          <i
                            class="bi bi-search position-absolute search-multi"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table shop-table">
                      <thead class="small-font">
                        <tr>
                          <th class="text-capitalize">Date</th>
                          <th class="text-capitalize">Shop Name</th>
                          <th class="text-capitalize">Salesman</th>
                          <th class="text-capitalize">Debit</th>
                          <th class="text-capitalize">Credit</th>
                          <th class="text-capitalize">Return</th>
                          <th class="text-capitalize">Remaining Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="balancesheetShop in balancesheetShops"
                          :key="balancesheetShop.id"
                        >
                          <td>
                            {{ formatDate(balancesheetShop.created_at) }}
                          </td>
                          <td>{{ balancesheetShop.shop_name }}</td>
                          <td>
                            {{
                              balancesheetShop.first_name +
                              ' ' +
                              balancesheetShop.last_name
                            }}
                          </td>
                          <td v-if="balancesheetShop.type == 'debit'">
                            {{ balancesheetShop.amount }}
                          </td>
                          <td v-else>---</td>
                          <td v-if="balancesheetShop.type == 'credit'">
                            {{ balancesheetShop.amount }}
                          </td>
                          <td v-else>---</td>
                          <td v-if="balancesheetShop.type == 'return'">
                            {{ balancesheetShop.amount }}
                          </td>
                          <td v-else>---</td>
                          <td>{{ balancesheetShop.remaining_amount }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              >
                <p class="d-lg-inline-block table-data">
                  Showing
                  {{ current_page * entries_per_page - (entries_per_page - 1) }}
                  to
                  {{
                    current_page * entries_per_page -
                    (entries_per_page - balancesheetShops.length)
                  }}
                  of {{ total_count }} entries.
                </p>
                <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                <div
                  class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                >
                  <v-pagination
                    class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="current_page"
                    :pages="total_pages"
                    :range-size="3"
                    @update:modelValue="loadData"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <generate-shop-balancesheet-pdf></generate-shop-balancesheet-pdf>
</template>
<script>
import { ref, reactive, onMounted, watch } from 'vue';
import balanceSheetServices from '@/services/BalanceSheetServices';
import shopServices from '@/services/ShopServices';
import moment from 'moment';
import VPagination from '@hennge/vue3-pagination';
import '@/assets/scss/pages/_daily.scss';
// Add this for multiselect
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import GenerateShopBalancesheetPdf from '@/components/modals/GenerateShopBalancesheetPdf.vue';

export default {
  components: {
    Multiselect,
    VPagination,

    GenerateShopBalancesheetPdf,
  },
  setup() {
    const total_count = ref(0);
    const balancesheetShops = ref([]);
    const total_pages = ref(0);
    const current_page = ref(1);
    const entries_per_page = ref(0);

    const shops = ref([]);
    const searchData = reactive({
      shopId: '',
      salesmanId: '',
    });

    const loadData = async () => {
      await balanceSheetServices
        .getshopsData(current_page.value, searchData)
        .then((response) => {
          balancesheetShops.value = response.data.data;
          total_pages.value = response.data.last_page;
          current_page.value = response.data.current_page;
          entries_per_page.value=response.data.per_page;
          total_count.value=response.data.total;
        })
        .catch((error) => {
          alert('error');
        });
    };
    const loadShops = async () => {
      await shopServices.getAllShops().then((response) => {
        shops.value = response.data;
      });
    };
    onMounted(() => {
      loadData();
      loadShops();
    });
    watch([searchData], ([newSearchData], [prevSearchData]) => {
      if (newSearchData.shopId == null) {
        newSearchData.shopId = '';
      }
      loadData();
    });
    const formatDate = (value) => {
      if (value) {
        return moment(String(value)).format('MMM Do YY');
      }
    };
    const selectFocus = ref(false);

    return {
      selectFocus,
      balancesheetShops,
      total_pages,
      current_page,
      loadData,
      formatDate,
      searchData,
      shops,
      entries_per_page,
      total_count,
    };
  },
};
</script>
