<template>
  <!-- Inner content -->
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">
                    {{ salesman_name }} Shops
                  </h4>
                </div>
                <div class="content-card-right ms-auto d-flex flex-wrap">
                  <a
                    href="#addShop"
                    data-bs-toggle="modal"
                    data-bs-target="#addShop"
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle me-1"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle me-1"
                    @click="counter_pdf_all"
                  >
                    <img
                      src="@/assets/images/pdf.png"
                      alt="pdf-image"
                      class="small-image white-image"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table shop-table">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Day</th>
                          <th class="text-capitalize text-center">Shops</th>
                          <th class="text-capitalize text-end pe-5">actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Saturday</td>
                          <td class="fw-bold text-center">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#salesmanShopDayView"
                              @click="day = 'saturday'"
                            >
                              <i class="bi bi-eye-fill me-2"></i> View Saturday
                              Shops
                            </a>
                          </td>
                          <td class="action text-end pe-4">
                            <a
                              href="#"
                              class="edit d-inline-block me-2 text-center"
                              data-bs-toggle="modal"
                              data-bs-target="#editShop"
                              @click="edit_day = 'saturday'"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="pdf_day = 'saturday',counter++"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Sunday</td>
                          <td class="fw-bold text-center">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#salesmanShopDayView"
                              @click="day = 'sunday'"
                            >
                              <i class="bi bi-eye-fill me-2"></i> View Sunday
                              Shops
                            </a>
                          </td>
                          <td class="action text-end pe-4">
                            <a
                              href="#"
                              class="edit d-inline-block me-2 text-center"
                              data-bs-toggle="modal"
                              data-bs-target="#editShop"
                              @click="edit_day = 'sunday'"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="pdf_day = 'sunday',counter++"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Monday</td>
                          <td class="fw-bold text-center">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#salesmanShopDayView"
                              @click="day = 'monday'"
                            >
                              <i class="bi bi-eye-fill me-2"></i> View Monday
                              Shops
                            </a>
                          </td>
                          <td class="action text-end pe-4">
                            <a
                              href="#"
                              class="edit d-inline-block me-2 text-center"
                              data-bs-toggle="modal"
                              data-bs-target="#editShop"
                              @click="edit_day = 'monday'"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="pdf_day = 'monday',counter++"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Tuesday</td>
                          <td class="fw-bold text-center">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#salesmanShopDayView"
                              @click="day = 'tuesday'"
                            >
                              <i class="bi bi-eye-fill me-2"></i> View Tuesday
                              Shops
                            </a>
                          </td>
                          <td class="action text-end pe-4">
                            <a
                              href="#"
                              class="edit d-inline-block me-2 text-center"
                              data-bs-toggle="modal"
                              data-bs-target="#editShop"
                              @click="edit_day = 'tuesday'"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="pdf_day = 'tuesday',counter++"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Wednesday</td>
                          <td class="fw-bold text-center">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#salesmanShopDayView"
                              @click="day = 'wednesday'"
                            >
                              <i class="bi bi-eye-fill me-2"></i> View Wednesday
                              Shops
                            </a>
                          </td>
                          <td class="action text-end pe-4">
                            <a
                              href="#"
                              class="edit d-inline-block me-2 text-center"
                              data-bs-toggle="modal"
                              data-bs-target="#editShop"
                              @click="edit_day = 'wednesday'"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="pdf_day = 'wednesday',counter++"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Thursday</td>
                          <td class="fw-bold text-center">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#salesmanShopDayView"
                              @click="day = 'thursday'"
                            >
                              <i class="bi bi-eye-fill me-2"></i> View Thursday
                              Shops
                            </a>
                          </td>
                          <td class="action text-end pe-4">
                            <a
                              href="#"
                              class="edit d-inline-block me-2 text-center"
                              data-bs-toggle="modal"
                              data-bs-target="#editShop"
                              @click="edit_day = 'thursday'"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="pdf_day = 'thursday',counter++"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <salesman-shop-day-view-modal
    :salesman_id="salesman_id"
    :day="day"
  ></salesman-shop-day-view-modal>
  <edit-salesman-shops-modal
    :salesman_id="salesman_id"
    :day="edit_day"
    @alert-event="showGrowl"
  ></edit-salesman-shops-modal>
  <add-salesman-shop-modal
    :salesman_id="salesman_id"
    @alert-event="showGrowl"
  ></add-salesman-shop-modal>
  <salesman-daily-shops-pdf
    :counter="counter"
    :salesman_id="salesman_id"
    :day="pdf_day"
    :salesman_name="salesman_name"
  ></salesman-daily-shops-pdf>
  <salesman-all-shops-pdf
    :salesman_id="salesman_id"
    :day="pdf_day"
    :salesman_name="salesman_name"
    :counter_all="counter_all"
  ></salesman-all-shops-pdf>
  <Alert
    v-if="growlMessage"
    :name="alert_m.name"
    :alertHeading="alert_m.heading"
    :alertDescription="alert_m.description"
  >
  </Alert>
</template>
<script>
import { ref, reactive, onMounted } from 'vue';
import '@/assets/scss/pages/_salesman-shop.scss';
import SalesmanServices from '@/services/SalesmanServices';
import SalesmanShopDayViewModal from '@/components/modals/SalesmanShopDayViewModal.vue';
import EditSalesmanShopsModal from '@/components/modals/EditSalesmanShopsModal.vue';
import AddSalesmanShopModal from '@/components/modals/AddSalesmanShopModal.vue';
import Alert from '@/components/alert.vue';
import SalesmanDailyShopsPdf from '@/components/pdf/SalesmanDailyShopsPdf.vue';
import SalesmanAllShopsPdf from '@/components/pdf/SalesmanAllShopsPdf.vue';
export default {
  components: {
    SalesmanShopDayViewModal,
    EditSalesmanShopsModal,
    Alert,
    AddSalesmanShopModal,
    SalesmanDailyShopsPdf,
    SalesmanAllShopsPdf,
  },
  props: {
    salesman_id: Number,
  },
  setup(props) {
    const counter=ref(0);
    const day = ref('');
    const edit_day = ref('');
    const salesman_name = ref('');
    const pdf_day = ref('');
    const counter_all = ref(0);
    const loadData = async () => {
      await SalesmanServices.getOneSalesman(props.salesman_id).then(
        (response) => {
          salesman_name.value =
            response.data.first_name + ' ' + response.data.last_name;
        }
      );
    };
    const alert_m = reactive({
      name: '',
      heading: '',
      description: '',
    });
    const growlMessage = ref(false);
    const showGrowl = (alertType) => {
      if (alertType == 'add') {
        alert_m.name = 'success';
        alert_m.description = 'Record Added Successfully';
      } else if (alertType == 'edit') {
        alert_m.name = 'success';
        alert_m.description = 'Record Updated Successfully';
      } else {
        alert_m.name = 'danger';
        alert_m.description = 'No Record Found. Please try with valid data';
      }

      alert_m.heading = 'Shops';
      growlMessage.value = true;
      setTimeout(() => {
        growlMessage.value = false;
      }, 5000);
    };
    onMounted(() => {
      loadData();
    });

    const counter_pdf_all = () => {
      counter_all.value++;
    };

    return {
      salesman_name,
      day,
      edit_day,
      growlMessage,
      showGrowl,
      alert_m,
      pdf_day,
      counter_pdf_all,
      counter_all,
      counter
    };
  },
};
</script>
