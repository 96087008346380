<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import BalanceSheetServices from '@/services/BalanceSheetServices';
export default {
    props: {
        salesman_id: Number,
        shop_id: Number,
        count_number: Number,
        start_date: String,
        end_date: String
    },
    setup(props) {
        const pdfData = ref([]);
        const pdfStats = ref([]);

        const documentDefinition = ref({});
        const body = ref([]);
        const salesman_name = ref('');
       
        const total_debit = ref(0);
        const total_return = ref(0);
        const total_credit = ref(0);

        const loadPdf = async () => {
            await BalanceSheetServices.getshopsPdfData(
                props.shop_id,
                props.salesman_id,
                moment(props.start_date).format('YYYY-MM-DD'),
                moment(props.end_date).format('YYYY-MM-DD')
            )
                .then(response => {
                    salesman_name.value =
                        response.data[0]['first_name'] + ' ' + response.data[0]['last_name'];
                        total_debit.value = 0,
                        total_credit.value = 0,
                        total_return.value = 0,
                    pdfData.value = response.data;
                })
                .then(() => {
                    let columns = [];
                    body.value = [];

                    columns = [
                        { text: 'Date', style: 'defaultFont', alignment: 'center' },
                        { text: 'ShopName', style: 'defaultFont', alignment: 'center' },
                        { text: 'Description ', style: 'defaultFont', alignment: 'center' },
                        { text: 'Debit', style: 'defaultFont', alignment: 'center' },
                        { text: 'Credit', style: 'defaultFont', alignment: 'center' },
                        { text: 'Return', style: 'defaultFont', alignment: 'center' },
                        { text: 'Remaining', style: 'defaultFont', alignment: 'center' }
                        
                    ];
                    body.value.push(columns);

                    let i = 1;
                    pdfData.value.forEach(function(row) {
                        const dataRow = [];

                        const created_at = {
                            text: moment(row['created_at']).format('DD-MM-YYYY'),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        dataRow.push(created_at);

                        const ShopName = {
                            text: row['shop_name'],
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center',
                           
                        };
                        dataRow.push(ShopName);

                        

                        const description = {
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        if (row['description'] == null) {
                            if(row['return_id']!=null){
                                description['text'] = 'Returned id #'+row['return_id'].toString();
                            }
                            if(row['type']==='credit'){
                                description['text']='Amount Received'.toString();
                            }
                            if(row['type']==='debit'){
                                description['text']='Amount Deducted'.toString();
                            }    
                            if(row['type']==='return'){
                                description['text']='Amount Returned'.toString();
                            }

                        } else {
                            description['text'] = row['description']?? '';
                       }
                        if(row['billing_id']!=null){

                            description['text'] += "  Billing Id : "+row['billing_id']
                        }

                        dataRow.push(description);
                        const debit = {
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        if (row['type'] === 'debit') {
                            total_debit.value += eval(row['amount']);
                            debit['text'] = row['amount'].toString();
                        } else {
                            debit['text'] = '---'.toString();
                        }

                        dataRow.push(debit);

                        const credit = {
                            //text: eval(row['price'].toString()),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        if (row['type'] === 'credit') {
                            total_credit.value += eval(row['amount']);
                            credit['text'] = row['amount'].toString();
                        } else {
                            credit['text'] = '---'.toString();
                        }

                        dataRow.push(credit);

                        const return_amount = {
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        if (row['type'] === 'return') {
                            total_return.value += eval(row['amount']);
                            return_amount['text'] = row['amount'].toString();
                        } else {
                            return_amount['text'] = '---'.toString();
                        }

                        dataRow.push(return_amount);


                        const remaining = {
                            text: row['remaining_amount'].toString(),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        dataRow.push(remaining);

                       
                        

                        body.value.push(dataRow);


                        i++;
                    });
                })
                .then(() => {
                    pdfMake.fonts = {
                        Roboto: {
                            normal:
                                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                            bold:
                                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                            italics:
                                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                            bolditalics:
                                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                        }
                    };
                    documentDefinition.value = {
                        content: [
                            {
                                columns: [
                                    
                                    {
                                        width: '100%',
                                        text:
                                            'DATE: ' +
                                            moment(props.start_date).format('DD-MM-YYYY') +
                                            '~' +
                                            moment(props.end_date).format('DD-MM-YYYY'),
                                        style: ['defaultFont', 'textRight', 'mb10']
                                    }
                                
                                ]
                            },
                            {
                                 columns:[
                                          {
                                        width: '100%',
                                        text:
                                            'Print Date: ' +
                                            moment().format('DD-MM-YYYY') ,
                                        style: ['defaultFont', 'textRight', 'mb10']
                                    }
                                 ]
                            },
                           

                            {
                                columns: [
                                    {
                                        width: '50%',
                                        text:
                                            'SALESMAN: ' +
                                            salesman_name.value +
                                            '(' +
                                            props.salesman_id +
                                            ')',
                                        style: ['defaultFont', 'mb2']
                                    }
                                ]
                            },
                            {
                                table: {
                                    headerRows: 1,
                                    dontBreakRows: true,
                                    // widths: ['*',70,60,60,60,'*','*'],
                                    //margin: [ 5, 2, 10, 20 ],
                                    body: body.value
                                }
                            },
                            {
                                columns: [
                                    {
                                        width: '85%',
                                        text: 'Total Debit : ' + total_debit.value.toFixed(2),
                                        style: ['defaultFont', 'textRight', 'mb15']
                                    },
                                    
                                ],
                                
                            },
                            {
                                columns: [
                                    {
                                        width: '86%',
                                        text: 'Total Credit : ' +total_credit.value.toFixed(2),
                                        style: ['defaultFont', 'textRight', 'mb15']
                                    },
                                ],
                            },
                            {
                                columns: [
                                    {
                                        width: '86%',
                                        text: 'Total Return : ' +total_return.value.toFixed(2),
                                        style: ['defaultFont', 'textRight', 'mb15']
                                    },
                                ],
                                
                            },
                            
                        ],
                        styles: {
                            defaultFont: {
                            fontSize: 11,
                            bold: true
                            },
                            textRight: {
                            alignment: 'right'
                            },
                            textCenter: {
                            alignment: 'center'
                            },
                            mt2: {
                            margin:[0,2,0,0]
                            },
                            mb2: {
                            margin:[0,0,0,2]
                            },
                            mb10: {
                            margin:[0,0,0,10]
                            },
                            mb15: {
                            margin:[0,3,0,2]
                            },
                            mCustom: {
                            margin:[20,2,0,0]
                            },
                            urduFont: {
                                font: 'urdu',
                            },
                            mtEnglish: {
                                margin:[0,10,0,0]
                            },
                        }
                    };
                })
                .then(() => {
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    pdfMake.createPdf(documentDefinition.value).open();
                });
        };
        watch(
            () => props.count_number,
            (currentValue, oldValue) => {
                    loadPdf();  
            }
        );

        return {
            pdfData,
            pdfStats,
            documentDefinition,
            body,
            salesman_name,
            
            total_debit,
            total_credit,
            total_return,
        };
    }
};
</script>
