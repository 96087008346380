<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import returnServices from '@/services/ReturnServices';
export default {
    props:{
        counter_all: Number,
        start_date: String,
        end_date: String,
    },
    setup(props){
        const pdfData=ref([]);
        const documentDefinition=ref({});
        const body=ref([]);
 const loadPdf= async()=>{
        await returnServices.pdfData(moment(String(props.start_date)).format('YYYY-MM-DD'), moment(String(props.end_date)).format('YYYY-MM-DD'))
        .then(response=>{
          pdfData.value= response.data.data;
        })
                .then(() => {
                    let columns=[];
                    body.value=[];
                    
                    columns=[
                        {text: 'No', style: 'defaultFont', alignment: 'center'},
                        {text: 'Date', style: 'defaultFont', alignment: 'center'},
                        {text: 'Name', style: 'defaultFont', alignment: 'center'},
                        {text: 'Total items', style: 'defaultFont', alignment: 'center'},
                        {text: 'Total Adjustments', style: 'defaultFont', alignment: 'center'}
                    ];
                    body.value.push(columns);
                    
                    let i=1;
                    pdfData.value.forEach(function(row) {

                        const dataRow=[];
                        const seriol_no={
                            text: i,
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center',
                            
                        };
                        dataRow.push(seriol_no);
                        const date={
                            text: row['created_at'],
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        }
                        dataRow.push(date);
                        const name={
                            style: ['defaultFont', 'mtEnglish'],
                            text: row['first_name']+' '+row['last_name']
                            
                        }
                        dataRow.push(name);
                        
                        const total_items={
                            text: row['total_items'],
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        
                        dataRow.push(total_items);
                        
                        const total_adjustments={
                            text: row['total_amount'],
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        
                        dataRow.push(total_adjustments);
                        body.value.push(dataRow);

                        //}
                        i++;
                    });
                   
                })
                .then(() => {
                    pdfMake.fonts = {
					
					Roboto: {
						normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
						bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
						italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
						bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
					},
				}
                    documentDefinition.value = {
                        content: [
                            
                            {
                                columns: [
                                    {
                                    width: '50%',
                                    text: 'Returns LIst',
                                    style: [ 'defaultFont', 'mb10' ]

                                    },
                                    {
                                    width: '50%',
                                    text: 'Print Date: '+moment().format('DD-MM-YYYY'),
                                    style: [ 'defaultFont', 'textRight', 'mb10' ]
                                    },
                                ],
                                
                            },
                            {
                                
                                table: {
                                    headerRows: 1,
                                     dontBreakRows: true, 
                                    widths: [30, '*','*','*' , '*'],
                                    body: body.value
                                }
                            },
                            
                        ],
                        styles: {
                            defaultFont: {
                            fontSize: 11,
                            bold: true
                            },
                            textRight: {
                            alignment: 'right'
                            },
                            textCenter: {
                            alignment: 'center'
                            },
                            mt2: {
                            margin:[0,2,0,0]
                            },
                            mb2: {
                            margin:[0,0,0,2]
                            },
                            mb10: {
                            margin:[0,0,0,10]
                            },
                            mb15: {
                            margin:[0,3,0,2]
                            },
                            mCustom: {
                            margin:[20,2,0,0]
                            },
                            
                            mtEnglish: {
                                margin:[0,10,0,0]
                            },
                        }
                        };
                })
                .then(() => {
                    
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    pdfMake.createPdf(documentDefinition.value).open();
                });

                
        }
        watch( ()=>props.counter_all, (currentValue, oldValue) => {
                loadPdf();
        });
        
        return {
            pdfData,
            documentDefinition,
            body,
            loadPdf,
        }
    }
}
</script>
