<template>
    <!-- Add Modal -->
<!-- addPayment Modal -->
	<div class="modal fade p-0" id="addPayment" tabindex="-1" aria-labelledby="addPaymentLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="d-flex flex-wrap w-100 position-relative event-auto">
				<div class="card shadow-none">
					<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
						<div class="content-card-left w-100">
							<h4 class="card-title text-white">Add Payment</h4>
						</div>
					</div>
					<div class="card-body" >
						<Form @submit="onSubmit" :validation-schema="schema" class="custom-form mt-2" ref="myForm">
							<div class="position-relative form-group mb-4 pb-4">
								
								
								<Field as="select" name="salesman_id" class="form-select custom-select" @click="selectFocus = true" v-model="salesman_id">
									<option value="0">Choose Salesman</option>
									<option v-for="salesman in salesmen" :value="salesman.id" :key="salesman.id">{{salesman.first_name+" "+salesman.last_name}}</option>
								</Field>
								<label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Salesman</label>
								<i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
								<p v-if="errors_v.get('salesmanId') && (salesman_id==null || salesman_id=='')" class="invalid-message mb-0 pt-2">
									{{errors_v.get('salesmanId')}}
								</p>
								<ErrorMessage class="invalid-message mb-0 pt-2" name="salesman_id" />
							</div>
							<div class="position-relative form-group mb-4 pb-3">
								<Multiselect
									v-model="shop_id"
									:options="shopOptions"
									class="custom-multiselect"
									placeholder="Shop"
									:searchable="true"
									:showOptions="true"
									mode="single"
									label="shop_name"
									trackBy	= "shop_name"
									valueProp="id"
									required: true
									:id="shop_multi"
									:name="shop_multi"
								/>
								<Field type="hidden" id="shop_id" name="shop_id" v-model="shop_id" />
								<label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Shop</label>
								<i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
								<p v-if="errors_v.get('shopId') && (shop_id==null || shop_id=='')" class="invalid-message mb-0 pt-2">
									{{errors_v.get('shopId')}}
								</p>
								<ErrorMessage class="invalid-message mb-0 pt-2" name="shop_id" />
							</div>
							<div class="position-relative mb-5">
								<Field type="number" name="credit_amount" :class="[credit_amount=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="credit_amount"/>
								<label for="" class="mb-0 placeholder-label">Amount</label>
								<p v-if="errors_v.get('creditAmount') && (credit_amount==null || credit_amount=='')" class="invalid-message mb-0 pt-2">
									{{errors_v.get('creditAmount')}}
								</p>
								<ErrorMessage class="invalid-message mb-0 pt-2" name="credit_amount" />
							</div>
							<!--<div class="position-relative">
								<textarea :class="[paymentModal=='' ? '' : 'active', 'border-0 position-relative w-100 resize-none']" v-model="paymentModal" rows="3"></textarea>
								<label for="" class="mb-0 placeholder-label">Payment Type</label>
							</div>
							-->
							<div class="card-footer pb-0 border-0 bg-transparent my-3">
								<button class="btn btn-primary w-100" :disabled="searchDisabled">
									<div v-if="loader" class="spinner-border me-2" role="status"></div>Add Billing
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>			
	</div>
</template>
<script>
import { ref, reactive, onMounted, watch } from 'vue';
import salesmanServices from '@/services/SalesmanServices';
import salesmanShopServices from '@/services/SalesmanShopServices';
import billingServices from '@/services/BillingServices';
import Multiselect from '@vueform/multiselect';
import Errors from "@/utils/Errors";
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from "yup";
export default {
	components:{
		Multiselect,
		Form,
		Field,
		ErrorMessage
		
	},
    setup(props,{emit}){
		const salesmen=ref([]);
		const salesman_id=ref(0);
		const shopOptions=ref("");
		const shop_id=ref(0);
		const credit_amount=ref("");
		const loader=ref(false);
        const searchDisabled=ref(false);
		const errors_v = reactive(new Errors());
		onMounted(async () => {
			await salesmanServices.paginateSalesman()
			.then(response=>{
				salesmen.value= response.data.data;
			})
			
		});
		watch(()=>salesman_id.value, (currentValue, oldValue) => {
			if(currentValue!=oldValue && currentValue!=""){
				salesmanShopServices.getSalesmanShops(salesman_id.value)
				.then(response=>{
					shopOptions.value= response.data.data;
				})
				 
			}
        });
		const schema = yup.object().shape({
            salesman_id: yup.number().moreThan(0, 'Salesman Required'),
			shop_id: yup.number().moreThan(0, 'Shop Required'),
			credit_amount: yup.string().required().label("Credit Amount"),
		});
		const closeModal= ()=>{
            const myModalEl = document.getElementById('addPayment');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
        }
		const onSubmit = async(values, actions) => {
			//alert(JSON.stringify(values, null, 2));
			//console.log(JSON.stringify(values, null, 2));
            const payload={
                "shopId": values.shop_id,
                "salesmanId": values.salesman_id,
				"creditAmount": values.credit_amount
			}
            if(values.salesman_id===0){
                 payload['salesmanId']="";
            }
			if(values.shop_id===0){
                 payload['shopId']="";
            }
            await billingServices.addCredit(payload)
            .then(async response=>{
                //resetCustomForm();
                loader.value=false;
                searchDisabled.value=false;
				closeModal();
                actions.resetForm();
				shop_id.value=0;
				shopOptions.value="";
                //router.push('/billing');
                emit('alertEvent', 'add');
            })
            .catch(error => {
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
                    errors_v.record(error.response.data.errors);
                }
            });
		}
        return {
            salesmen,
			salesman_id,
			shopOptions,
			shop_id,
			credit_amount,
			onSubmit,
			loader,
			searchDisabled,
			errors_v,
			schema,
			closeModal
        }
    }
}
</script>