<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import companyServices from '@/services/CompanyServices';
import moment from 'moment';
export default {
  props: {
    counter_all: Number,
  },
  setup(props) {
    const companies = ref([]);
    const documentDefinition = ref({});
    const body = ref([]);
    const loadPdf = async () => {
      await companyServices
        .paginateCompanies()
        .then((response) => {
          companies.value = response.data.data;
        })
        .then(() => {
          const columns = [
            { text: 'id', style: 'defaultFont', alignment: 'center' },
            { text: 'name ', style: 'defaultFont', alignment: 'center' },
            { text: 'type', style: 'defaultFont', alignment: 'center' },
            { text: 'mobile', style: 'defaultFont', alignment: 'center' },
          ];
          body.value.push(columns);
          let i = 1;
          companies.value.forEach(function (row) {
            const dataRow = [];
            const id = {
              text: i.toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(id);

            const name = {
              style: 'defaultFont',
              alignment: 'center',
            };

            if (row['name'] != '') {
              name['text'] = row['name'].toString();
            } else {
              name['text'] = '';
            }
            dataRow.push(name);

            const type = {
              text: row['type'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(type);

            const mobile = {
              text: row['mobile'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(mobile);
            i++;
            body.value.push(dataRow);
          });
        })
        .then(() => {
          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text: 'All Companies:',
                    style: ['defaultFont', 'mb2'],
                  },
                ],
              },
              {
                table: {
                  headerRows: 1,
                  widths: [30, '*', '*', '*'],
                  body: body.value,
                },
              },
              {},
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
              mt2: {
                margin: [0, 4, 0, 0],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
          body.value = [];
        });
    };
    watch(
      () => props.counter_all,
      (currentValue, oldValue) => {
        if (currentValue != oldValue && currentValue != '') {
          loadPdf();
        }
      }
    );

    return {
      companies,
      documentDefinition,
      body,
    };
  },
};
</script>
