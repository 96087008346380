<template>
    <!-- Edit Modal -->
	<div class="modal fade p-0" id="billingDetail" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="d-flex flex-wrap w-100 position-relative event-auto">
				<div class="card shadow-none">
					<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
						<div class="content-card-left">
							<h4 class="card-title text-white">Return Details</h4>
						</div>
						
						
					</div>
					<div class="card-body" v-for="returnDetail in returnDetails" :key="returnDetail.shop_id" >
						<div class="bill-wrapper">
							<div class="name d-flex flex-wrap align-items-center mb-2">
								<h6 class="mb-0">{{returnDetail.shop_name}}</h6>
							</div>
							<div class="table-responsive mb-0">
								<table id="my-table"  class="custom-table billing-table">
									<thead>
										<tr>
											<th class="text-capitalize">Product</th>
											<th class="text-capitalize">Quantity</th>
											<th class="text-capitalize">Amount</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="details_n in returnDetail.details" :key="details_n.product_id">
											
											<td>{{details_n.name}}</td>
											<td>{{details_n.quantity}}</td>
											<td>{{details_n.total_amount}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>


                    
				</div>
			</div>
		</div>			
	</div>
	<!--<billing-detail-shop-pdf :billing_id="billing_id" :shop_id="shop_id" ></billing-detail-shop-pdf>
	<billing-detail-all-pdf :billing_id="billing_id" :count_number="count_number" ></billing-detail-all-pdf>-->
</template>


<script>
import {ref, watch} from 'vue';
import returnServices from '@/services/ReturnServices';
//import BillingDetailShopPdf from '@/components/pdf/BillingDetailShopPdf.vue';
//import BillingDetailAllPdf from '@/components/pdf/BillingDetailAllPdf.vue';
export default {
	props:{
		return_id: Number
	},
	components:{
		//BillingDetailShopPdf,
		//BillingDetailAllPdf
	},
    setup(props){
		const returnDetails=ref([]);
		const count_number=ref(0);
		const shop_id=ref(0);
		watch(async()=>props.return_id, (currentValue, oldValue) => {
			
          if(currentValue!=oldValue && currentValue!="" && props.return_id>0){
            
			returnServices.getOneReturnDetail(props.return_id)
            .then(response=>{
                returnDetails.value=response.data.data;
          })
          .catch(error => {
                if(error.response.status==404){
					alert("error");
                  //closeModal();
                  //emit('alertEvent', 'no_data');
                }
            });
              
          }
        });

        return {
			returnDetails,
			count_number,
			shop_id
        }
    }
}
</script>