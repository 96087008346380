<template>
    <!-- Edit Modal -->
<div class="modal fade p-0" id="salesmanShopDayView" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="d-flex flex-wrap w-100 position-relative event-auto">
			<div class="card shadow-none">
				<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
					<div class="content-card-left">
						<h4 class="card-title text-white">Thursday</h4>
					</div>
					<div class="content-card-right ms-auto">
						<a href="#" class="hover-circle rounded-circle d-inline-block text-center">
							<img src="@/assets/images/pdf.png" class="small-image white-image" alt="Pdf_Image">
						</a>
					</div>
				</div>
				<div class="card-body" >
					<div class="bill-wrapper">
						<div class="table-responsive mb-0">
							<table id="my-table"  class="custom-table billing-table">
								<thead>
									<tr>
										<th class="text-capitalize">Name</th>
										<th class="text-capitalize">Balance</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="shop in shops" :key="shop.id">
                  
										<td>{{shop.shop_name}}</td>
										<td>{{shop.initial_balance}}</td>
									</tr>
									
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>			
</div>
</template>

<script>
import { ref, watch} from 'vue';
import SalesmanServices from '@/services/SalesmanShopServices';
export default {
    props:{
        salesman_id: Number,
        day: String
    },
    setup(props,{emit}){
        const shops=ref([]);
        const closeModal= ()=>{
            const myModalEl = document.getElementById('editCompany');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
        }
        watch(()=>props.day, (currentValue, oldValue) => {
          if(currentValue!=oldValue && currentValue!="" && props.salesman_id>0){
            SalesmanServices.getOneDaySalesmanShop(props.salesman_id,props.day)
              .then(response=>{
                shops.value=response.data.data;
              })
              .catch(error => {
                if(error.response.status==404){
                  closeModal();
                  //emit('alertEvent', 'no_data');
                }
            });
          }
        });
        
        return{
            shops,
            closeModal
        }
    }
}
</script>