<template>
    <!-- Inner content -->
    <div class="inner-content">
        <section>
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
                                <div class="content-card-left">
                                    <h4 class="card-title text-white">Salesman</h4>
                                </div>
                                <div class="content-card-right ms-auto">
                                    <a href="#addSalesman" data-bs-toggle="modal" data-bs-target="#addSalesman" @click.prevent class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle">
                                        <i class="bi bi-plus-square-fill text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="card-body" >
                                <div class="header-content">
                                    <div class="table-responsive mb-0">
                                        <table id="my-table"  class="custom-table shop-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-capitalize">Id</th>
                                                    <th class="text-capitalize">First Name</th>
                                                    <th class="text-capitalize">Last Name</th>
                                                    <th class="text-capitalize">Mobile</th>
                                                    <th class="text-capitalize">CNIC</th>
                                                    <th class="text-capitalize">Address</th>
                                                    <th class="text-capitalize text-center">actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="salesman in salesmen" :key="salesman.id">
                                                    <td>{{salesman.id}}</td>
                                                    <td>{{salesman.first_name}}</td>
                                                    <td>{{salesman.last_name}}</td>
                                                    <td>{{salesman.mobile}}</td>
                                                    <td>{{salesman.cnic}}</td>
                                                    <td>{{salesman.address}}</td>
                                                    <td class="action text-center">
                                                        <a href="#" class="edit d-inline-block me-2" data-bs-toggle="modal" data-bs-target="#editSalesman" @click="edit_id = salesman.id">
                                                            <i class="bi bi-pencil-fill"></i>
                                                        </a>
                                                        <a href="#" class="edit d-inline-block me-2" data-bs-toggle="modal" data-bs-target="#viewSalesman" @click="view_id = salesman.id">
                                                            <i class="bi bi-eye-fill"></i>
                                                        </a>
                                                        <router-link :to="`/salesman/shops/${salesman.id}`"  class="d-inline-block">
															<i class="bi bi-shop-window"></i>
														</router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
		
	<salesman-add-modal @alert-event="showGrowl"></salesman-add-modal>
    <salesman-view-modal :salesman_id="view_id" ></salesman-view-modal>
    <salesman-edit-modal :salesman_id="edit_id" @alert-event="showGrowl" ></salesman-edit-modal>
    <Alert
      v-if="growlMessage"
      :name="alert_m.name"
      :alertHeading="alert_m.heading"
      :alertDescription="alert_m.description">
    </Alert>
</template>
<script>
	import salesmanServices from '@/services/SalesmanServices';
	import {onMounted, ref, reactive} from 'vue';
    import "@/assets/scss/pages/_salesman.scss";
    import SalesmanAddModal from '@/components/modals/SalesmanAddModal.vue';
    import Alert from "@/components/alert";
    import SalesmanViewModal from '@/components/modals/SalesmanViewModal.vue';
	import SalesmanEditModal from '@/components/modals/SalesmanEditModal.vue';
    export default {
	    components:{
            SalesmanAddModal,
            Alert,
            SalesmanViewModal,
            SalesmanEditModal
        },
		setup() {
            const salesmen=ref([]);
            const view_id=ref(0);
            const edit_id=ref(0);
            const loadData= async()=>{
            await salesmanServices.paginateSalesman()
            .then(response=>{
                salesmen.value= response.data.data;  
            });
            }
            onMounted( () => {
                loadData();
            });
            const alert_m = reactive({
                name: '',
                heading: '',
                description: ''
            });
            const growlMessage = ref(false);
            const showGrowl = (alertType) =>{
            
            if(alertType=="add"){
                alert_m.name="success";
                alert_m.description="Record Added Successfully";
            }
            else if(alertType=="edit"){
                alert_m.name="success";
                alert_m.description="Record Updated Successfully";
            }
            else{
                alert_m.name="danger";
                alert_m.description="No Record Found. Please try with valid data";
            }
            
            
            alert_m.heading="salesman";
            growlMessage.value=true;
            setTimeout(() => { growlMessage.value=false; }, 5000)
            }
			return {
                salesmen,
                loadData,
                growlMessage,
                showGrowl,
                alert_m,
                view_id,
                edit_id
	        }
		}
	}
</script>