
<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import BillingServices from '@/services/BillingServices';
import moment from 'moment';
//import htmlToPdfmake from 'html-to-pdfmake';
export default {
    props:{
        billing_id: Number,
        count_number: Number
    },
    setup(props){
        
        const pdfData=ref([]);
        const pdfStats=ref([]);
        const billingData=ref([]);
        const documentDefinition=ref({});
        const body=ref([]);
        const totalBalance=ref(0);
        const totalCurrentBill=ref(0);
        const dayName=ref("");
        const total_quantity=ref(0);

        const urduText=(inputUrdu)=>{
            let inputString = inputUrdu;
            //inputString = inputString.replace("(", "-");
            //inputString = inputString.replace(")", "");
            inputString =  inputString.split(" ").reverse().join(" ");
            
            return inputString;
		}
        
        const loadPdf=async ()=>{
            await BillingServices.getDetailAllPdfContent(props.billing_id)
      			.then(response=>{	
                    pdfData.value=response.data.data;
                    console.log(response.data.data);
                    pdfStats.value=response.data.stats;
                    billingData.value=response.data.billingData;
      			})
                .then(() => {
                    let columns=[];
                    body.value=[];
                    
                    columns=[
                        {text: 'No', style: 'defaultFont', alignment: 'center'},
                        {text: 'Name', style: 'defaultFont', alignment: 'center'},
                        {text: 'Info', style: 'defaultFont', alignment: 'center'},
                        {text: 'Company', style: 'defaultFont', alignment: 'center'},
                        {text: 'Quantity', style: 'defaultFont', alignment: 'center'},
                        {text: 'Total Amount', style: 'defaultFont', alignment: 'center'}
                    ];
                    body.value.push(columns);
                    
                    let i=1;
                    pdfData.value.forEach(function(row) {
                        
                        //if(i<2){

                        
                        const dataRow=[];
                        const seriol_no={
                            text: i,
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center',
                            
                        };
                        dataRow.push(seriol_no);

                        /*const product_name={
                            style: ['defaultFont', 'urduFont'],
                            alignment: 'center',
                            direction: "rtl"
                        };
                        
                        if(row['productName']!="" ){
                            product_name['text']=urduText(row['productName'].toString());
                            //shop_name['text']="a";
                        }
                        else{
                            product_name['text']="";
                            
                        }
                        dataRow.push(product_name);
                        */
                       const product_urdu_name={
                            style: ['defaultFont', 'urduFont'],
                            alignment: 'center',
                            
                        };
                        const product_english_name={
                            style: ['defaultFont', 'mtEnglish'],
                            
                        }
                        if(row['productName']!="" && row['productName'] !=null ){
                            product_urdu_name['text']=urduText(row['productName']);
                            dataRow.push(product_urdu_name);
                        }
                        else{
                            product_english_name['text']=row['name'];
                            dataRow.push(product_english_name);
                            
                        }
                        
                        const extra_info={
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center',
                            
                        };
                        
                        if(row['extra_info']!="" ){
                            extra_info['text']=row['extra_info'];
                            //shop_name['text']="a";
                        }
                        else{
                            extra_info['text']="";
                            
                        }
                        dataRow.push(extra_info);


                        const company_name={
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        
                        if(row['companyName']!="" ){
                            company_name['text']=row['companyName'].toString();
                            //shop_name['text']="a";
                        }
                        else{
                            company_name['text']="";
                            
                        }
                        dataRow.push(company_name);

                        
                        const quantity={
                            text: row['quantity'].toString(),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        
                        dataRow.push(quantity);
                        total_quantity.value=eval(total_quantity.value)+eval(row['quantity']);
                        
                        const total={
                            text: row['total_amount'].toString(),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center', 
                            
                        }
                        dataRow.push(total);
                        body.value.push(dataRow);

                        //}
                        i++;
                    });
                   
                })
                .then(() => {
                    //const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    //const  d = new Date(props.billing_date);
                    //dayName.value = days[d.getDay()];
                    pdfMake.fonts = {
					urdu: {
						normal: 	  'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf',
						bold: 		  'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf',
						italics: 	  'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf',
						bolditalics:  'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf'
					},
					Roboto: {
						normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
						bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
						italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
						bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
					},
				}
                    documentDefinition.value = {
                        content: [
                            
                            {
                                columns: [
                                    {
                                    width: '50%',
                                    text: 'SALESMAN: '+billingData.value.first_name+" "+billingData.value.last_name+"("+billingData.value.salesman_id+")",
                                    style: [ 'defaultFont', 'mb10' ]

                                    },
                                    {
                                    width: '50%',
                                    text: 'DATE: '+moment(billingData.value.created_at).format('DD-MM-YYYY'),
                                    style: [ 'defaultFont', 'textRight', 'mb10' ]
                                    },
                                ],
                                
                            },
                            {
                                
                                table: {
                                    headerRows: 1,
                                     dontBreakRows: true, 
                                    widths: [30, '*','*', '*', '*', '*'],
                                    body: body.value
                                }
                            },
                            
                            {
                                columns: [
                                    {
                                    width: '21%',
                                    text: 'Total:',
                                    style: [ 'defaultFont', 'textRight', 'mb15']
                                    },
                                    {
                                    width: '47%',
                                    text: total_quantity.value,
                                    style: [ 'defaultFont', 'textRight', 'mb15' ]
                                    },
                                    {
                                    width: '40%',
                                    text: pdfStats.value.preDiscountedValue.toString(),
                                    style: [ 'defaultFont', 'textCenter', 'mb15']
                                    }
                                ],
                            },
                            {
                                columns: [
                                    {
                                    width: '38%',
                                    text: 'Discount: ',
                                    style: [ 'defaultFont', 'textCenter', 'mb15']
                                    },
                                    {
                                    width: '101%',
                                    text: pdfStats.value.discount.toString(),
                                    style: [ 'defaultFont', 'textCenter', 'mb15' ]
                                    },
                                ],
                            },
                            {
                                columns: [
                                    {
                                    width: '38%',
                                    text: 'Net Value:',
                                    style: [ 'defaultFont', 'textCenter', 'mb15']
                                    },
                                    {
                                    width: '101%',
                                    text: pdfStats.value.postDiscountedValue.toString(),
                                    style: [ 'defaultFont', 'textCenter', 'mb15']
                                    },
                                ],
                            },
                        ],
                        styles: {
                            defaultFont: {
                            fontSize: 11,
                            bold: true
                            },
                            textRight: {
                            alignment: 'right'
                            },
                            textCenter: {
                            alignment: 'center'
                            },
                            mt2: {
                            margin:[0,2,0,0]
                            },
                            mb2: {
                            margin:[0,0,0,2]
                            },
                            mb10: {
                            margin:[0,0,0,10]
                            },
                            mb15: {
                            margin:[0,3,0,2]
                            },
                            mCustom: {
                            margin:[20,2,0,0]
                            },
                            urduFont: {
                                font: 'urdu',
                            },
                            mtEnglish: {
                                margin:[0,10,0,0]
                            },
                        }
                        };
                })
                .then(() => {
                    
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    pdfMake.createPdf(documentDefinition.value).open();
                });

                
        }
        watch( ()=>props.count_number, (currentValue, oldValue) => {
			if(currentValue!=oldValue && currentValue!=""){
                
                loadPdf();
			}
        });
        
        return {
            pdfData,
            pdfStats,
            billingData,
            documentDefinition,
            body,
            totalBalance,
            totalCurrentBill,
            dayName,
            total_quantity,
            urduText
        }
    }
}
</script>
